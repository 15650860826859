export const thumbnail = (
  imageSrc: string | null | undefined,
  format = 'default'
) => {
  const runtimeConfig = useRuntimeConfig()
  const baseImgSrc = runtimeConfig.public.BASE_IMG_SRC

  // debug formats
  // return `https://placehold.co/${format}?text=${format}`

  if (!imageSrc) {
    return '/images/no-image.svg'
  }

  if (!baseImgSrc) {
    console.error('baseImgSrc is not defined')

    return imageSrc
  }

  if (imageSrc.startsWith('http')) {
    try {
      const regex = /images-cbre-cdn\.rshop\.sk\/.*?\/(?<image>.*)/
      const image = regex.exec(imageSrc)?.groups?.image

      if (!image) {
        throw new Error('Image not found')
      }

      imageSrc = image
    } catch (error) {
      console.error('Error parsing imageSrc', error)

      return imageSrc
    }
  }

  return baseImgSrc
    .replace('%25s', imageSrc)
    .replace('%s', imageSrc)
    .replace('%format', format)
}
