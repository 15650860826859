export default function getImageSrcSizesData(
  imageSrc: string | null | undefined,
  options: {
    sizes?: string
    srcset?: string
    srcsetToGenerate?: [string, string][]
    defaultFormat?: string
    includeGenericSrcset?: boolean
    preload?: boolean
  } = {}
) {
  const data = {
    src: thumbnail(imageSrc, options.defaultFormat),
    srcset: '',
    sizes: '',
  }

  if (thumbnail(imageSrc, options.defaultFormat)?.endsWith('/no-image.svg')) {
    return {
      src: imageSrc,
      srcset: '',
      sizes: '',
    }
  }

  options = {
    defaultFormat: 'default',
    includeGenericSrcset: true,
    preload: false,
    ...options,
  }

  if (options.sizes) {
    data.sizes += options.sizes
  }

  if (options.includeGenericSrcset) {
    const genericSizes = [
      50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1400, 1600,
      1800, 2000,
    ]

    for (const size of genericSizes) {
      data.srcset += `${thumbnail(imageSrc, `${size}x${size * 2}`)} ${size}w,`
    }
  }

  if (options.srcset) {
    data.srcset += options.srcset
  }

  if (options.srcsetToGenerate) {
    for (const srcsetToGenerate of options.srcsetToGenerate) {
      const thumb = thumbnail(imageSrc, srcsetToGenerate[0])

      data.srcset += `${thumb} ${srcsetToGenerate[1]},`
    }
  }

  // remove newlines and trailing comma, trim and also replace more than one space with just one
  data.sizes = data.sizes
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(/,$/, '')
    .replace(/\s{2,}/g, ' ')
    .trim()
  data.srcset = data.srcset
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(/,$/, '')
    .trim()

  if (options.preload) {
    useHead({
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: data.src,
          imagesrcset: data.srcset,
          imagesizes: data.sizes,
        },
      ],
    })
  }

  return data
}
